import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import LogoutSVG from "../../../assets/icons/logout.svg";
import "./index.scss";

const LogoutModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      className="logout_model"
      open={open}
      onClose={onClose}
      //   PaperProps={{
      //     sx: {
      //       borderRadius: "24px",
      //       padding: "16px",
      //       width: "100%",
      //       margin: "0%",
      //       maxWidth: {
      //         xs: "300px",
      //         sm: "350px",
      //         md: "400px",
      //       },
      //     },
      //   }}
      PaperProps={{
        sx: {
          borderRadius: "24px",
          padding: "16px",
          width: "90%",
          margin: "0 auto",
          maxWidth: "400px",
        },
      }}
    >
      <IconButton onClick={onClose} className="close_button">
        <CloseIcon />
      </IconButton>
      <DialogTitle className="dialog_svg">
        <img src={LogoutSVG} alt="logout" />
      </DialogTitle>
      <DialogContent className="dialog_content">
        <Typography variant="h6" className="dialog_text">
          Confirm Logout
        </Typography>
        <Typography variant="body1" className="dialog_discription">
          Do you want to end your session and log out now?
        </Typography>
      </DialogContent>
      <DialogActions className="dialog_action">
        <Button
          onClick={onConfirm}
          variant="contained"
          className="logout_button"
          sx={{
            textTransform: "capitalize",
          }}
        >
          <Typography className="logout_text">Yes, Logout</Typography>
        </Button>
        <Button
          onClick={onClose}
          variant="contained"
          className="stay_button"
          sx={{
            textTransform: "capitalize",
          }}
        >
          <Typography className="logout_text stay_text">
            No, Stay Here
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutModal;
